@use "../../../styles/variables.module.scss" as var;

// .indicator {
//     background: #EBEAED;
//     width: 15px;
//     height: 15px;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     &[data-active] {
//         background: #0154A4;
//     }
// }

.indicators {
  bottom: 60px;
  right: auto;
}

.indicator {
  background: #ebeaed;
  width: 15px;
  height: 15px;

  &[data-active] {
    background: var.$primaryColor;
  }
}

.controls {
  left: auto;
  gap: 32px;
  bottom: 48px;
  top: auto;
}

.control {
  width: 56px;
  height: 56px;
  border: 1px solid var.$primaryColor;
  background: none;
  border-radius: 999px;
}

.newsCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  box-shadow: 4px 0px 30px 0px #9cabfb33;
  background: #ffffff;
  gap: 16px;
  border-radius: 20px;

  .newsCardTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
  }

  .newsCardExtra {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #151439cc;
    svg {
      color: #151439cc;
    }
  }
}

.defaultCard {
  width: 634px;
  height: 224px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    height: fit-content;
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: nowrap;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 4px 0px 30px 0px #9cabfb33;
  background-color: white;
}
