@use '../../../styles/variables.module' as var;
.footerBasic {
  background-color: white;
  width: 100%;
  .titleText {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #151439cc;
    &:hover {
      color: var.$hoverColor;
    }
  }

  .divider {
    display: inline-block;
    height: 40px;
    border-right: 1px solid var.$secondColor;
  }

  .titleTextActive {
    color: var.$primaryColor;
    border-bottom: 1px solid var.$primaryColor;
    font-weight: 700;
    &:hover {
      color: var.$hoverColor;
    }
  }
}
