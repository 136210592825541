@use "styles/variables.module" as var;

.formDynamicTextDateSelectInput {
  position: relative;

  svg {
    stroke: black;
  }

  input {
    height: 24px;
    border-width: 2px;
    border-color: var.$secondColor;
    border-radius: 100px;

    &::placeholder {
      font-size: 12px;
    }

    &:focus {
      background-color: var.$secondColor;
      border-color: var.$primaryColor;
      color: var.$dynamicFormTextColor;

      &::placeholder {
        color: var.$dynamicFormTextColor;
      }
    }
  }

  textarea {
    border-width: 2px;
    border-color: var.$secondColor;
    height: 80px;

    &::placeholder {
      font-size: 12px;
    }

    &:focus {
      background-color: var.$secondColor;
      border-color: var.$primaryColor;
      color: var.$dynamicFormTextColor;
      &::placeholder {
        color: var.$dynamicFormTextColor;
      }
    }
  }

  label {
    font-size: var.$dynamicFormLabelSize;
    color: var.$primaryColor;
  }

  select {
    height: 24px;
    border-color: var.$secondColor;
    border-radius: 100px;

    &:focus {
      background-color: var.$secondColor;
      border-color: var.$primaryColor;
      color: var.$dynamicFormTextColor;
    }

    //border: none;
    //background-color: transparent;
    //
    //&:enabled {
    //  border-bottom: 1px solid var.$primaryColor;
    //}
  }

  .myFileInput {
    div {
      button {
        border-color: var.$secondColor;
      }

      div {
        button {
          border-color: transparent !important;
        }
      }
    }
  }
}

.checkboxRadioInput {
  input {
    &:checked {
      background-color: var.$primaryColor;
      border-color: var.$primaryColor;
    }
  }

  //label {
  //  text-overflow: ellipsis;
  //  overflow: hidden;
  //  white-space: nowrap;
  //}
}

.checkboxRadioItem {
  position: relative;
}
