@use "../../../../styles/variables.module" as var;

.research1RefactorCard {
  width: 100%;
  padding: 32px;
  background: #ffffff;
  border-radius: 32px;
  border: 1px solid var.$primaryColor;
  box-shadow: 0px 0px 30px 0px var.$lightBoxShadowColor;

  @media screen and (max-width: 800px) {
    gap: 40px;
  }

  .research1RefactorCardTitle {
    font-size: 36px;
    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
  }

  .research1RefactorCardInfo {
    display: flex;
    flex-direction: row;
    gap: 40px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }

  .research1RefactorDetailButton {
    &:hover {
      color: var.$primaryColor;
    }

    cursor: pointer;
  }
}
