.description {
  @media screen and (max-width: 800px) {
    div {
      flex-direction: column;
      width: 100% !important;
      gap: 0 !important;
    }
    img {
      display: none;
    }
  }
}
