@use "../../../styles/variables.module.scss" as var;

.jobCard3 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 256px;
  box-shadow: var.$boxShadowColor 0px 0px 20px -8px;
  border: 1px solid var.$primaryColor;
  border-radius: 32px;
  padding: 24px;
  background: white;
  color: var.$primaryColor;
  font-size: 14px;
  line-height: 24px;

  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.05px;
    text-align: left;
    color: var.$textHeading;
    height: 92px;
  }

  svg {
    color: var.$primaryColor;
  }
}
