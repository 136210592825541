@use "../../../styles/variables.module.scss" as var;

.search3 {
  display: flex;
  flex-direction: row;
  gap: 56px;
  align-items: center;
  background: white;
  padding: 32px 48px;
  @media screen and (max-width: 1000px) {
    padding: 10px 20px;
  }
  box-shadow: 0px 0px 30px 5px var.$lightBoxShadowColor;
  border-radius: 100px;
  position: relative;
  z-index: 2;

  .searchFields {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 56px;
    align-items: center;
    z-index: 2;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      gap: 28px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      .input,
      .inputMultiSelect {
        width: 100% !important;
        text-align: center;
        input,
        select {
          text-align: center;
          &::placeholder {
            text-align: center;
          }
        }
      }
      border-radius: 20px;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 28px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    .input,
    .inputMultiSelect {
      width: 100% !important;
      text-align: center;
      input,
      select {
        text-align: center;
        &::placeholder {
          text-align: center;
        }
      }
    }
    border-radius: 20px;
  }

  .label {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: var.$primaryColor;
  }

  .input {
    input,
    select {
      text-align: left;
      border: none;
      background: transparent;
      border-bottom: 1px solid hsla(249, 8%, 92%, 1);

      &:focus {
        border-bottom: 1px solid var.$primaryColor;
      }
    }
  }

  .inputMultiSelect {
    border: none !important;
  }

  .btn {
    width: 72px;
    height: 72px;
    border-radius: 40px;
    background: var.$primaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    @media screen and (max-width: 1000px) {
      width: 50px;
      height: 50px;
    }
  }
}

.bannerImage {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 84px 128px;
  bottom: 80px;

  @media screen and (max-width: 1000px) {
    position: static;
    padding: 0;
    img {
      border-radius: 0;
    }
  }
}

.searchContainer {
  padding: 44px 48px;
  @media screen and (max-width: 1000px) {
    padding: 10px;
    margin-top: 0 !important;
  }
}

.modalOkButton {
  &:hover {
    background-color: var.$hoverColor !important;
  }
}

.extraField {
  align-items: center;
  @media screen and (max-width: 1000px) {
    padding-right: 10px;
    align-items: flex-end;
    flex-direction: column;
  }
}
