.heroList {
  display: flex;
  flex-direction: column;
  gap: 0;
  @media screen and (max-width: 1000px) {
    gap: 22px;
  }
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  padding: 35px;
  width: 100%;

  @media screen and (max-width: 1000px) {
    align-items: start;
    flex-direction: column;
    padding: 20px;
    gap: 22px;
  }

  .title {
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 43.71px !important;
    text-align: left !important;
  }

  .description {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #151439cc !important;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}
