@use "@/styles/variables.module" as var;

.description {
  color: var.$primaryColor;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.research1Refactor {
  height: 100%;
  justify-content: center;
  z-index: 1;
  .research1RefactorTitle {
    @media screen and (max-width: 750px) {
      font-size: 30px;
    }

    color: var.$primaryColor;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
  }

  .textInput {
    width: 100%;
    padding: 8px 16px;
    background: white;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    input {
      background: transparent;
      border: none;
    }

    .submitButton {
      width: 100%;
      border-radius: 100px;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.86px;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .research1RefactorMainContent {
    padding: 40px 36px;
    @media screen and (max-width: 750px) {
      padding: 40px 18px;
      padding-top: 0px;
    }

    @media screen and (max-width: 600px) {
      padding: 40px 9px;
      padding-top: 0px;
    }
    width: 100%;
  }
}
