@use "../../../styles/variables.module.scss" as var;

.contactInfoHero {
  padding: 44px 64px;
  gap: 32px;
  @media screen and (max-width: 1000px) {
    padding: 10px 20px;
    gap: 16px;
  }
  .title {
    font-size: 48px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: left;
    color: var.$primaryColor;
    @media screen and (max-width: 1000px) {
      font-size: 32px;
    }
  }

  .contactInfo {
    display: flex;
    gap: 64px;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      gap: 32px;
    }

    .child {
      @media screen and (max-width: 1000px) {
        width: 100% !important;
      }
    }

    .contactInfoTitle {
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: var.$textHeading;
    }

    .contactInfoDescription {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.02em;
      text-align: left;
      color: var.$textDefault;
    }

    .contactInfoStack {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.02em;
      text-align: left;
      color: var.$textHeading;
    }
  }
}
