@use "../../../styles/variables.module.scss" as var;

.carousel {
  padding: 64px;
  gap: 64px;
  @media screen and (max-width: 1025px) {
    padding: 32px;
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: var.$textHeading;
    @media screen and (max-width: 1025px) {
      font-size: 25px;
      margin-bottom: 10px;
    }
  }

  .carouselNewsCard {
    height: auto;
    gap: 24px;
    padding: 32px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 94px 0px #0000000d;
  }

  .carouselNewsCardTag {
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    width: fit-content;
    text-align: center;
  }
  .carouselNewsCardTitle {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    line-height: 21.86px;
    color: var.$textHeading;
    height: 42px;
    position: relative;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var.$textDefault;
  }
}
