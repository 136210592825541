@use "variables.module" as var;

.player {
  width: 300px;
  height: 300px;
}

.boxDivider {
  width: 100px;
}

.checkBox {
  input {
    height: inherit !important;
  }
  a {
    text-decoration: underline;
    color: var.$primaryColor;
  }
}

.hero1 {
  .boxImg {
    position: relative;
    width: 496px;
    height: 552px;
    border: 12px solid var.$primaryColor;
    border-radius: 40px;

    .effectImg {
      position: absolute;
      left: -32px;
      top: -32px;
    }
  }

  .boxDivider {
    width: 100px;
  }

  .divider {
    height: 88px;
    position: relative;
    top: 11px;
  }

  .stackText {
    // padding-left: 28px;
    // border-left: 1px solid var.$secondColor;
    width: 176px;
    height: 110px;
  }

  .dataNumber {
    font-size: 48px;
    font-weight: 700;
    color: var.$primaryColor;
  }
}

.hero2 {
  margin: 0 auto;
  padding: 64px;

  .title {
    font-size: 56px;
    font-weight: 800;
    color: var.$primaryColor;
  }

  .description {
    font-size: 24px;
    font-weight: 400;
  }
}

.hero3 {
  width: 100%;
  height: 600px;
  position: relative;

  .list {
    width: fit-content;
    min-width: 500px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 64px;
    height: 200px;
    border-radius: 24px;
    position: relative;
    z-index: 1;
    margin: 0 auto;

    .title {
      font-size: 24px;
      font-weight: 900;
      border-bottom: 2px solid var.$primaryColor;
      padding-bottom: 16px;
      color: var.$primaryColor;
    }
  }

  .image {
    width: auto !important;
    position: absolute;
    bottom: 0;
    left: 64px;
    right: 64px;
    z-index: 0;
  }
}

.hero4 {
  height: 768px;
  background-color: white;
  padding: 64px;

  .title {
    color: var.$primaryColor;
    font-size: 100px;
    font-weight: 700;
    line-height: 136px;
  }

  .subtitle {
    color: var.$secondColor;
    font-size: 48px;
    font-weight: 400;
    line-height: 65px;
  }
}

.hero6 {
  .btn {
    &:hover {
      background-color: var.$hoverColor !important;
    }
  }
}

.search {
  .label {
    font-size: 20px;
    font-weight: 700;
    color: var.$primaryColor;
  }

  .input {
    width: 400px;
    border-bottom: 1px solid #e2e2e2;
  }

  input:focus {
    padding-bottom: 8px;
    border-bottom: 1px solid var.$primaryColor;
    color: var.$primaryColor;
  }
}

.search2 {
  .searchField {
    background: #ffffff;
    box-shadow: 0px 0px 30px 5px rgba(0, 136, 72, 0.2);
    border-radius: 100px;
    width: 100%;

    .label {
      font-weight: 700;
      color: var.$primaryColor;
    }
  }

  select,
  input {
    border: none;

    &:enabled {
      border-bottom: 1px solid var.$primaryColor;
    }
  }
}

.carousel1 {
  .title {
    font-weight: 700;
    color: var.$primaryColor;
    line-height: 70px;
  }

  .jobName {
    font-weight: 700;
    font-size: 20px;
    color: var.$primaryColor;
    cursor: pointer;
  }

  .boxDivider {
    width: 100px;
  }

  .carousel {
    width: 100%;

    .slide {
      cursor: pointer;
      overflow: hidden;
      // height: 590px;
      width: 100%;
      border-radius: 32px;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      color: rgba(37, 37, 37, 0.7);

      &:hover {
        border: 1px solid var.$primaryColor;
        box-shadow: 0px 0px 20px rgba(24, 144, 255, 0.25);
      }

      .flexBetween {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .jobName {
        font-weight: 700;
        font-size: 20px;
        color: var.$primaryColor;
        cursor: pointer;
      }
    }
  }
}

.carousel3 {
  .carousel3Title {
    font-weight: 700;
    font-size: 24px;
  }

  .summary {
    color: #252525;
    font-weight: 400;
    font-size: 12px;
  }

  .readMore {
    color: var.$primaryColor;
    font-size: 14px;
    font-weight: 400;
  }
}

.carousel5 {
  .title {
    font-weight: 700;
    font-size: 48px;
    color: var.$primaryColor;
    width: 100%;
    line-height: 66px;
  }

  .altTitle {
    height: 56px;
    padding-top: 12px;
    justify-content: center;
    display: flex;
    gap: 8px;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: var.$textHeading;
    position: relative;
    .coloredTitle {
      color: var.$primaryColor;
    }

    .background {
      width: 400px;
      position: absolute;
      display: inline-block;
      bottom: 0;
      height: 32px;
      border-radius: 94px;
      background-color: var.$primaryBasedBackground;
    }
  }

  .jobName {
    font-weight: 700;
    font-size: 20px;
    color: var.$primaryColor;
    cursor: pointer;
  }

  .boxDivider {
    width: 100px;
    border: 2px solid var.$secondColor;
  }

  .carousel {
    width: 100%;

    .card {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border-radius: 32px;
      background: #ffffff;
      width: 400px;

      .cardInfo {
        padding: 0px 24px 24px 24px;
        height: 265px;
        position: relative;
      }

      .cardImage {
        width: 400px !important;
      }

      .applyButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        width: 352px;
        height: 34px;

        position: absolute;
        bottom: 25px;
        background: var.$primaryColor;
        border-radius: 36px;

        &:hover {
          background-color: var.$hoverColor;
        }
      }
    }

    .dotButton {
      button {
        margin: 0 16px 0 0;
        border-radius: 100px;
        border: 10px solid #ebeaed;

        &:disabled {
          border-radius: 100px;
          border: 10px solid var.$primaryColor;
        }
      }
    }
  }
}

.carousel6 {
  .carousel {
    width: 100%;
  }

  .jobName {
    font-weight: 700;
    font-size: 20px;
    color: var.$primaryColor;
    cursor: pointer;
  }
}

.gallery1 {
  .container {
    padding: 80px;
    border-radius: 40px;

    .spaceBetween {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .divider {
      width: 100px;
    }

    .cardBody {
      padding: 40px;
      height: 50%;
      position: relative;

      .positionBottom {
        position: absolute;
        bottom: 40px;
        cursor: pointer;
      }

      .name {
        font-size: 28px;
        font-weight: 700;
        color: var.$primaryColor;
        margin-bottom: 24px;
      }
    }

    .item {
      width: 100%;
      height: 384px;
      border-radius: 40px;
      overflow: hidden;
      position: relative;

      .slide {
        position: absolute;
        width: 100%;
        height: 384px;
        transition: 1s;
        left: -500px;
        top: 0;
      }

      .itemBody {
        height: 100% !important;
        background: #fff;
      }

      &:hover {
        .slide {
          transition: 1s;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.gallery2 {
  width: 100%;
  padding: 44px 64px;

  .gallery2Main {
    padding: 44px;
    border-radius: 32px;

    .gallery2Body {
      .firstCard {
        width: 520px;
        height: 100%;
        .firstCardBody {
          height: 288px;
          padding: 20px;
          background: #ffffff;
          border-radius: 0 0 24px 24px;
        }
      }
    }

    .item {
      border-radius: 40px;
      overflow: hidden;
      position: relative;

      .gallery2TextComponent {
        width: 320px;
        height: 320px;
        padding: 24px;
        background: #ffffff;
        border-radius: 40px;
      }

      .slideCard {
        position: absolute;
        width: 320px;
        height: 320px;
        transition: 1s;
        left: -320px;
        border-radius: 40px;
        top: 0;
        z-index: 10;
      }

      &:hover {
        .slideCard {
          transition: 1s;
          left: 0;
          top: 0;
          img {
            width: 320px;
            height: 340px;
          }
          height: 340px;
        }
      }
    }

    .newsCard {
      width: 320px;
      height: 320px;
      padding: 24px;
      background: #ffffff;
      border-radius: 24px;

      .newsCardTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 21.86px;
        text-align: left;
        color: var.$textHeading;
      }
    }
  }
}

.gallery3 {
  width: 100%;
}

.gallery4 {
  width: 100%;
  padding: 88px 108px;
  background: rgba(0, 136, 72, 0.1);

  .gallery4Body {
    .firstCard {
      width: 520px;

      .firstCardBody {
        padding: 24px;
        height: 287px;
      }
    }
  }
}

.list1 {
  input:focus {
    border-color: var.$primaryColor;
  }

  .title {
    font-weight: 700;
    font-size: 48px;
    color: var.$primaryColor;
    width: 100%;
  }

  .subtitle {
    font-weight: 700;
    font-size: 32px;
    color: var.$primaryColor;
    width: 100%;
  }

  .boxDivider {
    width: 100px;
  }

  .btnApply {
    background: var.$primaryColor;
    border-radius: 36px;
    font-size: 20px;
    font-weight: 500;

    &:hover {
      background-color: var.$hoverColor;
    }
  }

  .jobName {
    font-weight: 700;
    font-size: 20px;
    color: var.$primaryColor;
  }
}

.list2 {
  .list {
    // height: calc(100vh - 250px - 96px);
    overflow: scroll;
    padding-bottom: 56px;
    width: 600px;

    .title {
      font-size: 24px;
      font-weight: 500;
      color: var.$primaryColor;
    }
  }

  .detail {
    width: calc(100% - 600px);
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;

    .title {
      font-size: 32px;
      font-weight: 500;
    }

    .btnApply {
      border-radius: 32px;
      background-color: var.$primaryColor;
      width: 300px;
    }
  }
}

.list3 {
  padding: 32px 64px;

  .title {
    font-weight: 700;
    font-size: 48px;
    color: var.$primaryColor;
    width: 100%;
  }

  .containerContent {
    position: relative;
    height: 250px;
    max-height: 250px;

    .name {
      color: #252525;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    .summary {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .infor {
      position: absolute;
      bottom: 0;
    }
  }
}

.list6 {
  padding: 44px 64px;
  width: 100%;

  .stackList {
    width: 43%;

    .listCard {
      height: 723px;
      overflow-y: scroll;
    }
  }

  .sortBtn {
    background-color: transparent;
    display: none;
  }

  .card {
    background: #ffffff;
    padding: 16px;
    border: 1px solid var.$secondColor;
    border-radius: 16px;
    width: 100%;

    .textTitle {
      color: var.$primaryColor;
      font-weight: 700;
      font-size: 22px;
      width: auto;
      max-width: 362px;
      height: 32px;
    }
  }

  .stackDetail {
    width: 52%;
    height: 723px;
    overflow-y: scroll;
  }

  .detailHeader {
    background: rgba(0, 136, 72, 0.1);
    padding: 24px 20.83px;

    .textHeader {
      font-size: 16px;
      @media screen and (max-width: 750px) {
        font-size: 14px;
      }
      font-weight: 400;
      color: rgba(21, 20, 57, 0.8);
    }

    .buttonPrimary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      width: 200px;
      height: 38px;

      background: var.$primaryColor;
      border-radius: 100px;

      &:hover {
        background: var.$hoverColor;
      }

      .textButtonPrimary {
        font-weight: 700;
        font-size: 16px;
        @media screen and (max-width: 750px) {
          font-size: 12px;
        }
        color: #ffffff;
      }

      @media screen and (max-width: 750px) {
        width: 110px;
      }
    }

    .buttonSecondary {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      width: 200px;
      height: 38px;
      @media screen and (max-width: 750px) {
        width: 110px;
      }
      background: #ffffff;
      border: 1px solid #008848;
      border-radius: 100px;

      .textButtonSecondary {
        font-weight: 700;
        font-size: 16px;
        color: var.$primaryColor;
        @media screen and (max-width: 750px) {
          font-size: 12px;
        }
      }
    }
  }

  .detailBody {
    padding: 24px;
    @media screen and (max-width: 750px) {
      padding: 8px;
    }

    .detailBodyHeader {
      width: 324px;

      .detailBodyHeaderText {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        width: 160px;
        color: #232323;
      }
    }

    .detailBodyDescriptionText {
      font-size: 16px;
      font-weight: 400;
      color: rgba(21, 20, 57, 0.8);
    }
  }
}

.list7 {
  width: 100%;
  padding: 44px 123px;
}

.list8 {
  width: 100%;
  padding: 44px 64px;

  a {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1300px) {
    padding: 10px 20px;
  }

  @media screen and (max-width: 850px) {
    padding: 5px 10px;
  }

  .card {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 32px;
    background: #ffffff;

    width: 400px;
    @media screen and (max-width: 450px) {
      width: 350px;
    }
    height: auto;

    .cardInfo {
      padding: 0px 24px 24px 24px;
    }

    .cardImage {
      width: 400px !important;

      @media screen and (max-width: 450px) {
        width: 350px !important;
      }
    }

    .applyButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;

      width: 352px;
      @media screen and (max-width: 450px) {
        width: 310px !important;
      }

      height: 34px;

      background: var.$primaryColor;
      border-radius: 36px;
      transition: 0.1s ease-in-out;

      &:hover {
        background-color: var.$hoverColor;
      }
    }
  }
}

.detail {
  padding: 44px 64px;
  @media screen and (max-width: 1000px) {
    padding: 15px 30px;
  }
  @media screen and (max-width: 750px) {
    padding: 5px 10px;
  }

  .title {
    font-weight: 700;
    font-size: 35px;
    @media screen and (max-width: 1000px) {
      font-size: 25px;
    }
    color: var.$primaryColor;
    width: 100%;
  }

  .subtitle {
    font-weight: 500;
    font-size: 25px;
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
    color: var.$primaryColor;
    width: 100%;
  }

  .divider {
    width: 100px;
  }

  .jobName {
    font-weight: 700;
    font-size: 20px;
    color: var.$primaryColor;
  }

  .applyButton {
    width: 250px;
    background-color: var.$primaryColor;
    border-radius: 100px;

    &:hover {
      background-color: var.$hoverColor;
    }
  }
}

.detail1 {
  padding: 56px 64px;

  .containerTitle {
    height: auto;
    padding: 40px;
    //background-color: var.$primaryColor;
    position: relative;

    .title {
      font-weight: 700;
      font-size: 48px;
      width: auto;
      line-height: 70px;
      color: var.$primaryColor;
      margin-bottom: 2%;
    }

    .containerInfor {
      position: absolute;
      bottom: 40px;

      .infor {
        font-size: 14px;
        font-weight: 400;
        //color: var.$primaryColor;
      }
    }
  }
}

.research1 {
  .title {
    font-weight: 700;
    font-size: 48px;
    color: var.$primaryColor;
    width: 100%;
  }

  .result {
    margin-top: 32px;
    padding: 32px;
    background: #fff;
    border-radius: 32px;
  }

  input {
    border-radius: 100px;

    &:focus {
      border-color: var.$primaryColor;
    }
  }
}

.form1 {
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 56px 64px;
  position: relative;
  // svg {
  //     // stroke: var.$primaryColor;
  // }
  .title {
    font-weight: 700;
    font-size: 48px;
    color: var.$primaryColor;
    width: 100%;
  }

  .subtitle {
    font-weight: 700;
    font-size: 24px;
    color: var.$primaryColor;
    width: 100%;
  }

  .boxBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .boxDivider {
    width: 100px;
  }

  .collapse {
    border-radius: 8px;
    border: 0.5px solid var.$primaryColor;
    /* background-color: #F56E23; */
    color: var.$primaryColor;
    cursor: pointer;
  }

  .childCol {
    border-left: 0.5px solid var.$primaryColor;
    border-bottom: 0.5px solid var.$primaryColor;
    border-right: 0.5px solid var.$primaryColor;
    margin: 0 12px;
    padding: 0 18px 18px 18px;
    border-radius: 0 0 8px 8px;

    .alignCenter {
      display: flex;
      align-items: center;
    }
  }

  .btnAdd {
    border-color: var.$primaryColor;
    color: var.$primaryColor;
  }

  .btnAddActive {
    background: var.$primaryColor;
    color: white;
  }

  input,
  button {
    height: 45px;

    &:focus {
      border-color: var.$primaryColor;
    }
  }

  label {
    display: flex;
    padding-bottom: 8px;

    .label {
      color: var.$primaryColor;
      font-size: 18px;
      width: fit-content;
    }
  }

  .actionBtnApply {
    font-weight: 400;
    font-size: 20px;
    border-radius: 40px;
    background-color: var.$primaryColor;
    width: 200px;

    &:hover {
      background-color: var.$hoverColor;
    }
  }
}

.subscribe1 {
  .title {
    font-size: 48px;
    font-weight: 700;
    color: var.$primaryColor;
  }

  .btn {
    border-radius: 40px;
    height: 56px;
    width: 260px;
    font-size: 20px;
    background-color: var.$primaryColor;
    right: 8px;

    &:hover {
      background-color: var.$hoverColor;
    }
  }

  input {
    font-size: 20px;
    width: 640px;
    height: 72px;

    &:focus {
      border-color: var.$primaryColor;
    }
  }
}

.subscribe2 {
  input {
    font-size: 10px;
    width: 300px;
    height: 18px;

    @media all and (min-width: 700px) {
      font-size: 20px;
      width: 730px;
      height: 72px;
    }

    &:focus {
      border-color: var.$primaryColor;
    }
  }

  .btn {
    background-color: var.$primaryColor;

    &:hover {
      background-color: var.$hoverColor;
    }
  }
}
