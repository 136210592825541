@use '../../../styles/variables.module.scss' as var;

.carouselHero {
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 10px;
  align-items: center;
  width: 100%;
  gap: 20px;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    padding: 96px 102px;
    gap: 100px;
  }
}

.imageBox {
  border: 12px solid var.$primaryColor;
  border-radius: 40px;
  width: 464px;
  height: 520px;
  margin-left: 32px;
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
  }
}
.image {
  position: static !important;
  top: 0;
  left: 0;
  img {
    width: 100% !important;
    height: 400px !important;
  }

  padding: 10px;
  @media screen and (min-width: 1200px) {
    position: absolute !important;
    top: 64px;
    left: 102px;
    img {
      width: 464px !important;
      height: 520px !important;
      border-radius: 40px;
    }
    padding: 0;
  }
}

.carousel {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  height: 400px;

  // @media screen and (min-width: 1400px) {
  //   top: 8%;
  // }

  @media screen and (min-width: 1200px) {
    left: 102px;
    top: 8%;
    width: 464px;
    height: 520px;
    position: absolute;
  }

  .carouselImage {
    img {
      height: 400px !important;
    }

    @media screen and (min-width: 1200px) {
      img {
        height: 520px !important;
      }
    }
  }
}
.rightSide {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 20px;
  width: 100%;
  @media screen and (min-width: 1200px) {
    padding: 52px 0;
    gap: 32px;
    width: calc(100% - (464px + 100px + 102px));
  }
}

.rightSideTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media screen and (min-width: 1200px) {
    align-items: start;
  }

  .divider {
    border-top-color: var.$secondColor !important;
  }
}

.title {
  font-size: 32px;
  text-align: center;
  @media screen and (min-width: 1200px) {
    font-size: 48px;
    text-align: left;
  }
  font-weight: 700;
  line-height: 65.57px;
  color: var.$primaryColor;
}

.description {
  margin-top: 16px;
  font-size: 14px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    line-height: 30px;
    font-size: 18px;
    text-align: left;
  }
}

.rightSideData {
  display: flex;
  flex-direction: column;

  gap: 12px;

  @media screen and (min-width: 1200px) {
    justify-content: start;
    flex-direction: row;
    align-items: center;
    gap: 28px;
  }

  .dataGroup {
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-items: center;
    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }

    .data {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .verticalDivider {
    position: static;
    width: 88px;
    height: 0;
    border-top: 2px solid var.$secondColor !important;
    @media screen and (min-width: 1200px) {
      height: 88px;
      width: 0;
      border-top: none !important;
      border-left: 2px solid var.$secondColor !important;
    }
  }
}
