@use "../../../styles/variables.module.scss" as var;

.jobCardDetail {
  height: 100%;
  gap: 0;
  border-radius: 24px;

  .header {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 24px;
    gap: 24px;
    background-color: transparent;
    background-position-x: right;
    background-position-y: center;

    .headerTitle {
      color: var.$primaryColor;
      font-size: 32px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: 0em;
      text-align: left;
    }

    .itemList {
      display: flex;
      gap: 16px;
      font-size: 16px;
      line-height: 24px;
      color: var.$textDefault;
      .item {
        display: flex;
        gap: 8px;
      }
    }

    .btn {
      width: 200px;
      background-color: var.$primaryColor;
      border-radius: 100px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;

      &:hover {
        background-color: var.$hoverColor;
      }
    }
  }

  .description {
    overflow-y: auto;
    gap: 48px;
    padding: 24px;
    text-align: left;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    .descriptionTitle {
      color: var.$textHeading;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
    }

    ul {
      padding-left: 24px;
    }
  }
}
