@use "../../../styles/variables.module.scss" as var;
.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  color: var.$primaryColor;
}

.startTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 43.71px;
  text-align: left;
  color: var.$primaryColor;
}

.searchCountText {
  color: var.$textHeading;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.divider {
  display: inline-block;
  width: 100px;
  border-top: 2px solid var.$secondColor;
}
.jobList {
  padding-top: 48px;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 96px;

  .listAndDetail {
    width: 100%;
    height: 900px;
    gap: 32px;
    display: flex;
    justify-content: space-between;
    .list {
      gap: 24px;
      height: 900px;
      overflow-y: auto;
    }
  }
}

.titleAlt1 {
  height: 56px;
  // padding-top: 12px;
  justify-content: center;
  display: flex;
  gap: 8px;
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 44px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: var.$textHeading;
  position: relative;
  width: fit-content;

  .backgroundAlt1 {
    width: 400px;
    position: absolute;
    display: inline-block;
    bottom: 8px;
    height: 32px;
    border-radius: 94px;
  }
}
