@use "../../../styles/variables.module.scss" as var;

.jobCardRect {
  gap: 10px;
  padding: 16px;
  border: 1px solid hsla(241, 3%, 67%, 1);
  border-radius: 16px;
  background: white;
  width: 100%;
}

.jobCardRectSelected {
  width: 100%;
  gap: 10px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 5px hsla(206, 82%, 50%, 0.16);
  border: 1px solid var.$primaryColor;
  background: var.$primaryBasedBackground;
}

.title {
  width: 100%;
  gap: 10px;
  color: var.$primaryColor;
  align-items: center;
  position: relative;
  .jobName {
    font-weight: 700;
    font-size: 22px;
    line-height: 30.05px;
  }
  .tag {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    gap: 4px;
    align-items: center;
    position: relative;
  }
}

.description {
  gap: 16px;
  align-items: center;
  color: var.$textDefault;
  font-size: 14px;
  line-height: 24px;
  .item {
    gap: 4px;
  }
}
