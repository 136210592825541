@use "../../../styles/variables.module.scss" as var;

.footer2 {
  padding: 64px 32px;
  gap: 44px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1025px) {
    padding: 10px 20px;
    gap: 10px;
    flex-direction: column;
    align-items: start;
  }
  align-items: center;
  width: 100%;

  .verticalDivider {
    display: inline-block;
    height: 55px;
    border-left: 1px solid var.$secondColor;
    @media screen and (max-width: 1025px) {
      border-left: none;
      height: 0;
      width: 55px;
      border-top: 1px solid var.$secondColor;
    }
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    color: var.$textHeading;
  }

  .socialMediaGroup {
    .socialMediaText {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      color: var.$textHeading;
    }
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (max-width: 1025px) {
      flex-direction: row;
      gap: 0px;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      gap: 16px;
      flex-direction: column;
      width: 100%;
    }
    .socialMediaItem {
      display: flex;
      gap: 8px;
      flex-direction: row;
      width: 100%;
      @media screen and (max-width: 1025px) and (min-width: 769px) {
        width: 33%;
      }
    }
  }

  .footer2Group {
    width: 50%;
    align-items: start;
    @media screen and (max-width: 1025px) {
      width: 100%;
    }
  }

  .textGroup {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  .textGroupItem {
    width: 33%;
    flex-wrap: nowrap;
    @media screen and (max-width: 800px) {
      display: flex;
      width: 100%;
    }
  }
}
