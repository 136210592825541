@use "../../../styles/variables.module.scss" as var;

.carousel8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  padding-top: 48px;

  // Đây là style dùng riêng cho carousel9
  .title9 {
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
  }
  .content9 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  //========================================================================

  .title {
    height: 56px;
    // padding-top: 12px;
    justify-content: center;
    display: flex;
    gap: 8px;
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 44px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: var.$textHeading;
    position: relative;
    width: fit-content;
    z-index: 0 !important;

    .coloredTitle {
      color: var.$primaryColor;
    }

    p {
      z-index: 2;
    }

    .background {
      width: 400px;
      position: absolute;
      display: inline-block;
      bottom: 8px;
      height: 32px;
      border-radius: 94px;
    }
  }

  .carousel {
    border: 1px solid;
    padding: 64px 0;
  }

  .card {
    border: 1px solid var.$secondColor;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    text-align: center;
    .cardTitle {
      color: var.$textHeading;
      font-weight: 700;
      line-height: 30.05px;
      font-size: 22px;
    }
    .cardSubtitle {
      color: var.$primaryColor;
      font-weight: 700;
      line-height: 21.86px;
      font-size: 16px;
    }

    .cardDescription {
      color: var.$textDefault;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
