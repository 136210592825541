@use 'variables.module' as var;

.heading1 {
  font-size: 48px;
  font-weight: 700;
  color: var.$primaryColor;
}

.heading2 {
  font-size: 32px;
  font-weight: 700;
  color: black;
}

.heading3 {
  font-size: 16px;
  font-weight: 700;
  color: black;
}
