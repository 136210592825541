@use "../../../../styles/variables.module" as var;
.researchCard2 {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 1360px;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
  @media screen and (max-width: 1100px) {
    gap: 32px;
  }
  border-radius: 24px;
  background: white;
  border: 1px solid var.$primaryColor;
  box-shadow: 0px 0px 30px 0px var.$lightBoxShadowColor;

  .mainInfo {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;

    @media screen and (max-width: 1100px) {
      flex-direction: column-reverse;
    }

    .leftSide {
      display: flex;
      flex-direction: row;
      gap: 56px;
      flex: 1 1 0;
      @media screen and (max-width: 1100px) {
        flex-direction: column;
        gap: 32px;
      }

      .image {
        img {
          border-radius: 24px;
          border: 1px solid var.$primaryColor;
        }
      }
      .mainInfoMiddle {
        display: flex;
        flex: 1 1 0;
        width: 100%;
        flex-direction: column;
        gap: 16px;

        .title {
          font-size: 32px;
          font-weight: 600;
          line-height: 43.71px;
          text-align: left;

          color: var.$primaryColor;
        }

        .descriptionGrid {
          width: 100%;
          .descriptionItem {
            text-align: left;
            display: flex;
            gap: 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var.$textHeading;
            .itemTitle {
              display: flex;
              text-wrap: nowrap;
              gap: 8px;
              min-width: 140px;
              color: var.$primaryColor;
              svg {
                color: var.$primaryColor;
              }
            }
          }
        }
      }
    }

    .rightSide {
      width: fit-content;
      @media screen and (max-width: 1100px) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .bottomInfo {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .cardTitle {
      font-size: 32px;
      font-weight: 600;
      line-height: 43.71px;
      text-align: left;
      color: var.$primaryColor;
    }
  }
}
