$primaryColor: var(--primaryColor);
$secondColor: var(--secondaryColor);
$textHeading: var(--textHeading);
$textDefault: var(--textDefault);
$hoverColor: var(--hoverColor);
$focusColor: var(--focusColor);
$padding: var(--padding);
$spacing: var(--spacing);
$boxShadowColor: var(--boxShadowColor);
$lightBoxShadowColor: var(--lightBoxShadowColor);
$primaryBasedBackground: var(--primaryBasedBackground);
$dynamicFormTextColor: var(--dynamicFormTextColor);
$dynamicFormLabelSize: var(--dynamicFormLabelSize);
:export {
  primaryColor: $primaryColor;
  secondColor: $secondColor;
  hoverColor: $hoverColor;
  focusColor: $focusColor;
  textHeading: $textHeading;
  textDefault: $textDefault;
  boxShadowColor: $boxShadowColor;
  lightBoxShadowColor: $lightBoxShadowColor;
  primaryBasedBackground: $primaryBasedBackground;
  dynamicFormTextColor: $dynamicFormTextColor;
  dynamicFormLabelSize: $dynamicFormLabelSize;
  spacing: $spacing;
  padding: $padding;
}
