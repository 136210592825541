@use "../../../styles/variables.module.scss" as var;

.cardContainer {
  padding: 12px 24px;
  border: 1.5px solid #a7a7ac;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  background-color: #fff;

  &:hover {
    // box-shadow: 0px 0px 30px 0px #9cabfb80;
    border-color: var.$primaryColor;
  }

  .btnApply {
    background-color: var.$primaryColor;
    &:hover {
      filter: brightness(90%);
    }
  }
}
