@use "../../styles/variables.module.scss" as var;

.text {
  padding: 4px 16px;
  width: 100%;
  position: relative;

  &:hover {
    background-color: var.$lightBoxShadowColor;
  }
}
.textActive {
  background-color: var.$lightBoxShadowColor;
}

.textWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
