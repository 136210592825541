@use "../../../styles/variables.module.scss" as var;

.coreValueList {
    gap: 44px;
    padding: 44px 64px;

    @media screen and (max-width: 768px) {
        padding: 22px 32px;
        
    }

    .title {
        color: var.$primaryColor;
        font-size: 48px;
        @media screen and (max-width: 768px) {
            font-size: 32px;
        }
        font-weight: 700;
        line-height: 65px;
        letter-spacing: 0em;
        text-align: left;

    }
    .tag {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: var.$textDefault;

    }

    .coreValueDescription {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }

    .coreValueTitle {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;

    }

    .coreValueImages {
        display: flex;
        gap: 24px;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 1000px) {
          flex-direction: column;

          .coreValueImage {
            width: 100%!important;
          }
        }
    }
}