@use "@/styles/variables.module" as var;

.heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.text1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.footer3 {
  display: flex;
  gap: 48px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
