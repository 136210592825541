@use "../../../styles/variables.module.scss" as var;
.jobCard {
  width: 400px;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 350px;
    height: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 0;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #0000001a;
  border-radius: 32px;

  .image {
    img {
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      width: 400px;
      height: 344px;
      @media screen and (max-width: 768px) {
        width: 300px;
      }
    }
  }

  .info {
    width: 400px;
    @media screen and (max-width: 768px) {
      width: 300px;
    }
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .infoMain {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .defaultText {
        display: flex;
        gap: 8px;
        color: var(--primary-4);
        svg {
          color: var(--primary-4);
        }
        font-size: 14px;
        line-height: 21px;
      }

      .top {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .title {
        position: relative;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: var(--primary-4);
      }
    }
    .btn {
      display: flex;
      height: 34px;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      font-size: 16px;
      width: 352px;
      @media screen and (max-width: 768px) {
        width: 300px;
      }
      cursor: pointer;
      background-color: var(--primary-4);
      border-radius: 36px;
      color: #fff;
      &:hover {
        background-color: var.$hoverColor;
        color: black;
      }
    }
  }
}
