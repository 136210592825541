@use "../../../styles/variables.module.scss" as var;

.jobCard {
  border: 1px solid var.$primaryColor;
  gap: 10px;
  border-radius: 32px;
  box-shadow: var.$boxShadowColor 0px 0px 20px -8px;
  background: hsla(0, 0%, 100%, 1);
  height: 320px;
  padding: 24px;
  display: flex;

  .jobCardMain {
    width: 272px;
    gap: 24px;
    .title {
      font-size: 24px;
      font-weight: 700;
      color: var.$textHeading;
      position: relative;
    }

    .group {
      gap: 16px;
      height: 24px;
      color: var.$primaryColor !important;
      & svg {
        color: var.$primaryColor !important;
      }
      font-size: 12px;
      line-height: 24px;
      width: 100%;
    }

    .description {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 30px !important;
      letter-spacing: 0.02em !important;
      text-align: left !important;
      color: var.$textDefault !important;

      span,
      p {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 30px !important;
        letter-spacing: 0.02em !important;
        text-align: left !important;
        color: var.$textDefault !important;
      }
    }
  }
}
