@use 'variables.module' as var;

.menu1 {
  .boxText {
    width: auto;

    .text {
      text-align: center;
      width: fit-content;
      cursor: pointer;

      &:hover {
        color: var.$primaryColor;
      }
    }

    .active {
      color: var.$primaryColor;
      border-bottom: 1px solid var.$primaryColor;
      font-weight: 800;
    }
  }
}

.menuSmall {
  width: 100%;
  z-index: 99;

  .boxText {
    width: auto;

    .text {
      text-align: center;
      width: fit-content;
      cursor: pointer;

      &:hover {
        color: var.$primaryColor;
      }
    }

    .active {
      color: var.$primaryColor;
      border-bottom: 1px solid var.$primaryColor;
      font-weight: 800;
    }
  }
}


